<template>
    <div>
        <jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
            <div>
                <el-form :model="diaform" ref="diaform" :rules="rules">
                    <el-form-item label="定点编号:" prop="pointNo" :label-width="formLabelWidth">
                        <el-input v-model="diaform.pointNo"></el-input>
                    </el-form-item>
                    <el-form-item label="定点名称:" prop="name" :label-width="formLabelWidth">
                        <el-input v-model="diaform.name"></el-input>
                    </el-form-item>
                    <el-form-item label="别名:" prop="alias" :label-width="formLabelWidth">
                        <el-input v-model="diaform.alias"></el-input>
                    </el-form-item>
                    <el-form-item label="定点类型:" prop="type" :label-width="formLabelWidth">
                        <el-select v-model="diaform.type" placeholder="请选择">
                            <el-option v-for="item in lboptionsTrue" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="经停线路:" prop="routeId" :label-width="formLabelWidth">
                        <el-input placeholder="请选择" v-model="diaform.routeName" @focus="selectInstitutions" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="具体位置:" prop="address" :label-width="formLabelWidth">
                        <el-input v-model="diaform.address"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
        <institutions-tree ref="institutions" @addInstitutions="xladdInstitutions" url="/waybillOperate/getTree" title="选择线路" :defaultProps="props"></institutions-tree>
    </div>
</template>
<script>
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
export default {
    props: {
        lboptions: {
            type: Array,
            default () {
                return []
            }
        }
    },
    computed: {
        lboptionsTrue: function() {
            return this.lboptions.filter(one => one.value)
        }
    },
    data() {
        return {
            // 对话框类型
            type: '',
            title: '',
            props: {
                children: 'children',
                label: 'text',
                value: 'id',
            },

            diaform: {
                pointNo: '',
                name: '',
                alias: '',
                type: '',
                routeId: '',
                routeName: '',
                address: '',
                remark: ''
            },
            dialogVisible: false,
            formLabelWidth: '120px',
            yysoptions: [{
                    value: '0',
                    label: '电信'
                },
                {
                    value: '1',
                    label: '移动'
                },
                {
                    value: '2',
                    label: '联通'
                }
            ],

            rules: {
                pointNo: [{
                    required: true,
                    message: '请输入定点编号',
                    trigger: 'blur'
                }],
                name: [{
                    required: true,
                    message: '请输入定点名称',
                    trigger: 'blur'
                }],
                type: [{
                    required: true,
                    message: '请选择定点类型',
                    trigger: 'change'
                }],
                routeId: [{
                    required: true,
                    message: '请选择经停线路',
                    trigger: 'change'
                }],
            }
        }
    },
    components: {
        institutionsTree,
    },
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('diaform')
                this.diaform = {
                    pointNo: '',
                    name: '',
                    alias: '',
                    type: '',
                    routeId: '',
                    routeName: '',
                    address: '',
                    remark: ''
                }
            }
        }
    },
    methods: {
        // 打开对话框
        init(option, type) {
            this.dialogVisible = true
            this.type = type || 'add'
            if (this.type == 'update') {
                this.title = '编辑'
                this.$nextTick(() => {
                    this.diaform = {
                      ...this.diaform,
                      ...JSON.parse(JSON.stringify(option))
                    }
                })
            } else {
                this.title = '新增'
            }
        },

        // 所属机构
        xladdInstitutions(data) {
            // console.log(data)
            this.diaform.routeId = data.id
            this.diaform.routeName = data.text
        },
        // 组织
        selectInstitutions() {
            this.$refs.institutions.init()
        },

        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },

        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        save() {
            this.$refs.diaform.validate(valid => {
                if (valid) {
                    let url = ''
                    if (this.type == 'add') {
                        url = '/baseinfopoint/save'
                    } else {
                        url = '/baseinfopoint/update'
                    }
                    let option = this.diaform
                    this.$http.post(url, option).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        this.dialogVisible = false
                        this.$emit('updateList')
                    })
                } else {
                    return false
                }
            })
        }
    }
}

</script>
<style lang="scss" scoped>
</style>
